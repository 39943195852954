import { Subscription } from 'rxjs';
import { GeolocationService } from './../../services/geolocation/geolocation.service';
import { Component, Input, OnInit } from '@angular/core';
import { NotyfService } from 'src/app/services/notyf/notyf.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {

  el: HTMLElement;
  input: HTMLInputElement;
  parent: HTMLElement;

  locationPermissionGiven: boolean = false;

  @Input() search_location: string = '';
  @Input() search_query: string = '';

  private permission_sub: Subscription = null;

  date_input: string = '';
  time_input: string = '';

  get date_url() {
    return encodeURIComponent(this.date_input);
  }

  get time_url() {
    return encodeURIComponent(this.time_input);
  }

  constructor(
    private notyf: NotyfService,
    private geolocation: GeolocationService,
  ) {}



  async ngOnInit() {
    // watch for when an element is visble in the viewport (page)
    const observer = new IntersectionObserver(function(entries) {
    	// isIntersecting is true when element and viewport are overlapping (visible on page)
      // isIntersecting is false when element and viewport don't overlap (element is off page)
      if(entries[0].isIntersecting === true) {
        // element visible
      } else {
        // element not visible 
      }
    }, { threshold: [0] });

    observer.observe(document.querySelector('app-search'));

    this.locationPermissionGiven = this.geolocation.hasPermission();
    this.permission_sub = this.geolocation.permission$.subscribe((permissionGiven: boolean) => {
      this.locationPermissionGiven = permissionGiven;

      if (this.permission_sub !== null) {
        this.permission_sub.unsubscribe();
      }
    });
  }

  getLocation(el: HTMLElement) {
    this.el = el;
    this.parent = el.closest('.input-group');
    this.input = this.parent.querySelector('.form-control');

    this.geolocation.getAddress().then(address => {
      this.input.value = address;
    }).catch(error => {
      this.notyf.error('There was an error getting your location.');
      console.log({error});
    });
  }
}
