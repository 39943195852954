import { NotyfService } from './../notyf/notyf.service';
import { Subscription } from 'rxjs';
import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic/ngx';
import { ApiService } from './../api/api.service';
import { AuthService } from './../auth/auth.service';
import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { INotificationPayload } from 'cordova-plugin-fcm-with-dependecy-updated';
// import { FirebaseService } from '../firebase/firebase.service';

@Injectable({
  providedIn: 'root'
})
export class FcmService {

  hasPermission: boolean;
  token: string = null;
  pushPayload: INotificationPayload;

  private fcmIsSetup: boolean = false;

  private tokenRefreshSub: Subscription = null;
  private onNotificationSub: Subscription = null;
  private saveDeviceTokenSub: Subscription = null;
  private webTokenSub: Subscription = null;
  private loggedInSub: Subscription = null;

  constructor(
    private platform: Platform,
    private auth: AuthService,
    private api: ApiService,
    private fcm: FCM,
    private notyf: NotyfService,
    // private firebase: FirebaseService,
  ) { 
    this.loggedInSub = this.auth.loggedIn$.subscribe((isLoggedIn: boolean) => {
      if (this.fcmIsSetup === false) {
        if (isLoggedIn === true) {
          this.setupFCM();
        }
      } else {
        if (isLoggedIn === false) {
          this.removeToken();
        }
      }
    });
  }

  async getToken() {
    this.token = await this.fcm.getToken();

    return this.token;
  }

  async getPermission() {
    this.hasPermission = await this.fcm.requestPushPermission();
  }

  checkHasPermission() {
    return this.hasPermission;
  }

  async getPushPayload() {
    this.pushPayload = await this.fcm.getInitialPushPayload();
  }

  async setupFCM() {
    const isLoggedIn = await this.auth.isLoggedIn();

    if (isLoggedIn === false || this.fcmIsSetup === true) {
      return;
    }

    if (this.platform.is('cordova')) {
      const wasPermissionGiven: boolean = await this.fcm.requestPushPermission();

      if (wasPermissionGiven) {
        this.setupMobile();
      }
    } 
  }

  private setupMobile() {
    this.fcm.hasPermission().then((hasPermission: boolean) => {
      if (hasPermission) {
        if (this.tokenRefreshSub === null) {
          this.tokenRefreshSub = this.fcm.onTokenRefresh().subscribe((newToken) => {
            this.token = newToken;
            this.saveToken();
          });
        }
        
        if (this.onNotificationSub === null) {
          this.onNotificationSub = this.fcm.onNotification().subscribe((payload) => {
            // this function is ran when a push notification is received when the user is using the app
            // when the app is minimised/in the background or closed, a system notification is delivered as usual
            this.pushPayload = payload;
            this.notyf.open({
              type: 'info',
              message: `<strong>${payload.title}</strong><br>${payload.body}`,
              duration: 0,
              dismissible: true,
              icon: false
            });
          });
    
        }
    
        this.getPermission();
        this.getToken();
        this.getPushPayload();
    
        this.saveToken();
      }
    });
  }

  // private setupWeb() {
  //   if (this.webTokenSub === null) {
  //     this.webTokenSub = this.firebase.requestPermission().subscribe(
  //       token => {
  //         console.log('permission for notifications granted!');
  //         this.saveToken(token);
  //         this.firebase.receiveMessage();
  //       },
  //       error => {
  //         console.log('error requesting permission.');
  //         console.log({error});
  //         this.webTokenSub.unsubscribe();
  //         this.webTokenSub = null;
  //       }
  //     );
  //   }
  // }

  get pushPayloadString() {
    return JSON.stringify(this.pushPayload, null, 4);
  }

  saveToken(token = null) {
    if (token === null) {
      token = this.token;
    }

    if (token !== null) {
      if (this.saveDeviceTokenSub !== null) {
        this.saveDeviceTokenSub.unsubscribe();
      }
  
      this.saveDeviceTokenSub = this.api.post('/user/save-device-token', {token: token}).subscribe(
        response => {
          if (this.token !== null) {
            this.fcmIsSetup = true;
          }
  
          this.saveDeviceTokenSub.unsubscribe();
        },
        error => {
          // 401 is unauthorised - the user isnt logged in
          if (error.status !== 401) {
            console.log({error});
          } else {
            this.auth.logout();
          }
        }
      );
    }
  }

  async removeToken() {
    const response = await this.api.post('/user/remove-device-token', {token: this.token}).toPromise();
    this.fcmIsSetup = false;
  }
}
