import { CartService } from './../services/cart/cart.service';
import { AlertController, NavController } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SingleSalonGuard implements CanActivate {
  constructor(
    private alertController: AlertController,
    private navController: NavController,
    private cartService: CartService,
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return new Promise<boolean>(async (resolve, reject) => {
      const cart = this.cartService.getCart();

      if (! localStorage.getItem('current-salon') || cart.length === 0) {
        resolve(true);
        return;
      }
      
      const current_salon = JSON.parse(localStorage.getItem('current-salon'));
      
      if (current_salon.slug !== next.params['slug']) {
        const alert = await this.alertController.create({
          header: 'Clear existing order?',
          message: 'You have already started an order with ' + current_salon.name + '. Do you want to clear this order and start a new one?',
          buttons: [
            {
              text: 'Cancel',
              role: 'cancel',
              handler: () => {
                alert.dismiss().then(() => { 
                  resolve(false); 
                });

                return false;
              }
            },
            {
              text: 'Clear and Proceed',
              role: 'proceed',
              handler: () => {
                alert.dismiss().then(() => { 
                  this.cartService.clearCart();
                  // localStorage.removeItem('current-salon');
                  localStorage.removeItem('in_use_voucher');
                  resolve(true);
                });

                return true;
              }
            }
          ]
        });

        await alert.present();
      } else {
        resolve(true);
      }
    })
  }
}
